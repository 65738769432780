@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

body {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
    url("./static/images/loginBackground.jpg")
      no-repeat top center fixed;
  -webkit-background-size: 100% 110%;
  -moz-background-size: 100% 110%;
  -o-background-size: 100% 110%;
  background-size: 100% 110%;

  overflow: hidden;
  // font-family: "Roboto" !important;
  background-position: center;
  height: 100vh;
}
#root {
  width: 100%;
  height: 100vh;
  position: relative;
}


a:hover{
  text-decoration: none!important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar {
  width: 0.2rem !important;
  height: 0.2rem !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

